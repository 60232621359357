import { useState, useEffect, useContext } from 'react';
import { Context } from '@/lib/Context';
import { useRecoilState, useRecoilValue } from 'recoil';
import { wsListState } from '@/lib/store';


export const useWebSocket = (wsType: "obookWebSocket" | "ortexWebSocket" | "multiWebSocket", identifier: string, startMessage: any, updateData: any, dependencies: any[] = [], unsubscribe: boolean = true, condition: boolean = false, retry: boolean = false) => {
    const { multiWebSocket, ortexWebSocket, obookWebSocket } = useContext(Context);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [wsConnections, setWSConnections] = useRecoilState(wsListState);

    const getWebSocketInstance = () => {
        switch (wsType) {
            case "obookWebSocket":
                return obookWebSocket;
            case "ortexWebSocket":
                return ortexWebSocket;
            case "multiWebSocket":
                return multiWebSocket;
            default:
                return ortexWebSocket;
        }
    };

    useEffect(() => {
        if (!condition) {
            if (!wsConnections.includes(identifier) || retry) {
                setWSConnections((prevState) => [...prevState, identifier]);
                switch (wsType) {
                    case undefined:
                        if (ortexWebSocket) {
                            ortexWebSocket.readyState === 1 ? ortexWebSocket.send(startMessage) : ortexWebSocket.addEventListener("open", () => ortexWebSocket.send(startMessage));
                            ortexWebSocket.removeEventListener("message", updateData);
                            ortexWebSocket.addEventListener("message", updateData);
                        }
                        break;

                    case "ortexWebSocket":
                        if (ortexWebSocket) {
                            ortexWebSocket.readyState === 1 ? ortexWebSocket.send(startMessage) : ortexWebSocket.addEventListener("open", () => ortexWebSocket.send(startMessage));
                            ortexWebSocket.removeEventListener("message", updateData);
                            ortexWebSocket.addEventListener("message", updateData);
                        }
                        break;

                    case "obookWebSocket":
                        if (obookWebSocket) {
                            obookWebSocket.readyState === 1 ? obookWebSocket.send(startMessage) : obookWebSocket.addEventListener("open", () => obookWebSocket.send(startMessage));
                            obookWebSocket.removeEventListener("message", updateData);
                            obookWebSocket.addEventListener("message", updateData);
                        }
                        break;

                    case "multiWebSocket":
                        if (multiWebSocket) {
                            multiWebSocket.readyState === 1 ? multiWebSocket.send(startMessage) : multiWebSocket.addEventListener("open", () => multiWebSocket.send(startMessage));
                            multiWebSocket.removeEventListener("message", updateData);
                            multiWebSocket.addEventListener("message", updateData);
                        }
                        break;
                }
            } else {
                switch (wsType) {
                    case undefined:
                        ortexWebSocket.addEventListener("message", updateData);
                        break;
                    case "obookWebSocket":
                        obookWebSocket.addEventListener("message", updateData);
                        break;
                    case "ortexWebSocket":
                        ortexWebSocket.addEventListener("message", updateData);
                        break;
                    case "multiWebSocket":
                        multiWebSocket.addEventListener("message", updateData);
                        break;
                }
            }
        }

        return () => {
            if (unsubscribe && condition) {
                const ws = getWebSocketInstance()
                if (ws && ws.readyState === 1) {
                    let unsubscribeMessage = JSON.parse(startMessage)
                    unsubscribeMessage.type = "unsubscribe"
                    ws.send(JSON.stringify(unsubscribeMessage))
                    ws?.removeEventListener("message", updateData);
                    setWSConnections(prev => prev.filter(conn => conn !== identifier));
                }
            }
        };

    }, [multiWebSocket, obookWebSocket, ortexWebSocket, wsType, ...dependencies]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 60000);
        return () => clearTimeout(timer);
    }, []);

    return { isLoading };
};
